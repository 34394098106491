import React, { useState, useCallback } from "react";
import Button from "../shared/buttonScroll";
import {
  HeroContainer,
  HeroBg,
  HeroContent,
  // HeroH1,
  // HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./component";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import ProfilePic from "./profilePic";

const Hero = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <HeroContainer>
      <HeroBg>
        {/* <Particles
          id="particles"
          init={particlesInit}
          loaded={particlesLoaded}
          url="http://foo.bar/particles.json"
        /> */}
        <Particles
          id="particles"
          init={particlesInit}
          loaded={particlesLoaded}
          width={"100%"}
          height={1000}
          options={{
            fullScreen: { enable: false },
            background: {
              color: {
                value: "#0d47a1",
              },
            },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                directions: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 1000,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              // size: {
              //   value: { min: 1, max: 5 },
              // },
            },
            detectRetina: true,
          }}
        />
      </HeroBg>
      <HeroContent>
        <ProfilePic />
        {/* <HeroH1>Get to know me better</HeroH1> */}
        {/* <HeroP>Scroll down to see what I have to offer</HeroP> */}
        <HeroBtnWrapper>
          <Button
            to="contact"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Contact me {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
