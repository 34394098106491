import React from "react";
import {
  FaLinkedin,
  // FaFacebook,
  // FaInstagram,
  // FaTwitter,
  FaDev,
  FaGithubSquare,
} from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./component";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <WebsiteRights onClick={toggleHome}>
              Nathan J Millar © {new Date().getFullYear()} All rights Reserved
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="//www.linkedin.com/in/nathanmillar/"
                target="_blank"
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </SocialIconLink>
              {/* <SocialIconLink
                href="https://www.facebook.com/nm1603/"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>{" "} */}
              {/* <SocialIconLink
                href="//www.instagram.com/millar1603/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink> */}
              {/* <SocialIconLink
                href="//twitter.com/nmillar16"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink> */}
              <SocialIconLink
                href="//dev.to/nathanmillar16"
                target="_blank"
                aria-label="Dev"
              >
                <FaDev />
              </SocialIconLink>
              <SocialIconLink
                href="//github.com/nathanmillar16/"
                target="_blank"
                aria-label="GitHub"
              >
                <FaGithubSquare />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
