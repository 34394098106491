import CookieConsent from "react-cookie-consent";
// import isDev from "../../utils/isDev";
import { PrivacyLink, acceptBtn } from "./component";

const cookiePolicyContainer = () => {
  return (
    <>
      <CookieConsent
        enableDeclineButton
        onDecline={() => {
          window.location.href = "https://www.google.com/";
        }}
        buttonText={"Continue"}
        buttonStyle={acceptBtn}
      >
        This website uses cookies for analytical purposes.
        <PrivacyLink href="https://www.termsfeed.com/live/b95953e5-9c68-47b7-b199-9544491993cb">
          Privacy/Cookie Policy
        </PrivacyLink>
      </CookieConsent>
    </>
  );
};

export default cookiePolicyContainer;
