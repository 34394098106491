export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "About Me",
  headline: "Senior Front End Developer in the East Midlands",
  imgStart: false,
  img: require("./../../images/svg-1.svg").default,
  alt: "Contact",
  dark: true,
  primary: true,
  darkText: false,
  buttonTo: "/contact",
};

export const homeObjFour = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Contact Me",
  headline: "Get in touch with me",
  buttonLabel: "Contact Me",
  imgStart: true,
  img: require("./../../images/svg-4.svg").default,
  alt: "Contact",
  dark: false,
  primary: false,
  darkText: true,
  href: "//www.linkedin.com/in/nathanmillar/",
};
