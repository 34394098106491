import styled from "styled-components";

export const PrivacyLink = styled.a`
  padding-left: 2rem;
  color: #676262;
`;

export const acceptBtn = {
  background: "#4488bb",
  color: "#fff",
};
