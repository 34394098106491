import React, { useState } from "react";
import Footer from "../components/footer";
import Navbar from "../components/header/navbar";
import Sidebar from "../components/header/sidebar";
import Hero from "../components/hero";
import InfoSection from "../components/info";
import { homeObjOne, homeObjFour } from "../components/info/data";
import CookiePolicy from "../components/cookiePolicy";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Hero />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjFour} />
      <Footer />
      <CookiePolicy />
    </>
  );
};

export default Home;
