import React from "react";
import { ProfilePictureContainer } from "./component";

const profilePic = () => {
  return (
    <ProfilePictureContainer
      src="profile.jpg"
      alt="Profile Picture"
    />
  );
};

export default profilePic;
